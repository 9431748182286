import "./thankYou.css";
import { Loader, thankyou } from "../../../assets/images";
import { thankuleft } from "../../../assets/images";
import { withTranslation } from "react-i18next";
import useThankYouHook from "../thankYou/useThankYou";
import { commaValidation } from "../../../utility";

const ThankYou = (props) => {
  const {
    bookingData,
    // diffDate,
    loader,
    navigateToMyAccount,
  } = useThankYouHook();
  const { t } = props;

  const thankyoupage = () => {
    return (
      <div className="thank_umain flt">
        <div className="thanku_img flt">
          <img src={thankyou} alt="thank" />
        </div>
        <div className="thanku_booking flt">{t("Thank_Booking")}</div>
        {/* <div className="thanku_content flt">
                    {t("Please_see_the_booking_details_below_&")}<span onClick={navigateToMyAccount} type="button">{t("my_account")}.</span></div> */}
        <div className="thanku_row flt">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-3">
              <div className="thanku_leftimg flt">
                <img src={thankuleft} alt="thank" />
              </div>
            </div>
            <div className="col-sm-5">
              <div className="thanku_right flt">
                <ul className="thanku_cont">
                  <li>{t("Total_Guest")}</li>
                  <li>
                    {bookingData?.data?.[0]?.adults +
                      bookingData?.data?.[0]?.children}
                  </li>
                  <li>{t("Total_Rooms")}</li>
                  <li>
                    {bookingData?.data?.[0]?.no_of_room
                      ? bookingData?.data?.[0]?.no_of_room
                      : "0"}
                  </li>
                  <li>{t("Check_In")}</li>
                  <li>{bookingData?.data?.[0]?.arrival_date}</li>
                  <li>{t("Check_Out")}</li>
                  <li>{bookingData?.data?.[0]?.departure_date}</li>
                  <li>{t("Total_Length")}</li>
                  <li>
                    {bookingData?.data?.[0]?.total_length
                      ? bookingData?.data?.[0]?.total_length
                      : "0"}
                  </li>
                  <li>{t("Amount_Paid")}</li>
                  <li>{`₹ ${commaValidation(
                    Number(bookingData?.data?.[0]?.total_price)
                  )}`}</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-2"></div>
          </div>
          <div className="thanku_questions flt">
            {" "}
            {t("Please_see_the_booking_details_below_&")}
            <span onClick={navigateToMyAccount} type="button">
              {t("my_account")}.
            </span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {loader === "loading" && (
        <div className="main_load ">
          <img src={Loader} alt="loader" />
        </div>
      )}
      {loader !== "loading" && (
        <div className="main_container flt">{thankyoupage()}</div>
      )}
    </>
  );
};

export default withTranslation()(ThankYou);
