import "./App.css";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Provider } from "react-redux";
import store from "./store";
import { useEffect } from "react";
import { alertActions } from "./actions/alert.actions";
import "./assets/css/style.css";
import Home from "./components/pages/home/Home";
import TopBar from "./components/_common/topbar/TopBar";
import Standerd from "./components/pages/room/Standard";
import BookingDetails from "./components/pages/bookingDetails/BookingDetails";
import Login from "./components/pages/login/Login";
import MyAccount from "./components/pages/myAccount/Myaccount";
import { userLogout } from "./actions/UserAction";
import ThankYou from "./components/pages/thankYou/ThankYou";

const Main = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state?.alert);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;
  const session_expired = user?.userData?.session_expired;


  /**
   * alert
   */
  useEffect(() => {
    setTimeout(() => dispatch(alertActions.clear()), 3000)
    // eslint-disable-next-line
  }, [alert.message]);

  /**
   * session expire
   */
  useEffect(() => {
    if (session_expired) {
      dispatch(alertActions.error("Authentication error..Please login"));
      setTimeout(() => {
        dispatch(userLogout());
        navigate("/login");
      }, 100);
    }
    // eslint-disable-next-line
  }, [session_expired]);

  return (
    <div>
      {alert.message && (
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      )}

      <Routes>
        <Route path="/topbar" element={<TopBar />} />
        <Route path="/standard" element={<Standerd />} />
        <Route path="/bookingDetails" element={<BookingDetails />} />
        <Route path="/myAccount" element={<MyAccount />} />
        <Route path="/" element={<Home />} />
        <Route path="/thankYou" element={<ThankYou />} />
        <Route path="/Login" element={isUser ? <Home /> : <Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}
