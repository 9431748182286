import * as axios from "../network/Network";

const config = require("../network/api.json");

class BookingDetailsService {
  /**
   * get booking id
   * @param {*} id
   * @returns
   */
  static getBooking(booking_id) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.booking,
      data: { booking_id },
    });
  }
  /**
   * make payment
   * @param {*} amount
   * @param {*} booking_id
   * @returns
   */
  static makePayment(amount, booking_id, timediff, difference, rooms) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.payment,
      data: { amount, booking_id, timediff, difference, rooms },
    });
  }

  /**
   * payment success
   * @param {*} razorpay_order_id
   * @param {*} razorpay_payment_id
   * @param {*} razorpay_signature
   * @param {*} booking_id
   * @returns
   */
  static payment_success(
    razorpay_order_id,
    booking_id,
    name,
    phone,
    amountdata
  ) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.success_payment,
      data: { razorpay_order_id, booking_id, name, phone, amountdata },
    });
  }

  static amount_calculation(arrival_date, departure_date, roomTypeId, rooms) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.amount_calculation,
      data: { arrival_date, departure_date, roomTypeId, rooms },
    });
  }

  /**
   * getBookings
   */
  static getBookings(booking_id) {
    return axios.appRequest({
      method: axios.GET,
      url: config.user.bookings + `/${booking_id}`,
    });
  }
}
export { BookingDetailsService };
